<template>
  <div :style="`--accent-color: ${university.color};`" id="app">
    <v-app class="app-content" light>
      <div v-if="loaded">
        <Navigation/>
        <router-view style="padding-bottom: 78px;" @chat="chat($event)" @changeTitle="title = $event"></router-view>
        <div class="d-block d-md-none" style="height: 32px"></div>
      </div>
      <div v-else style="width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center">
        <v-progress-circular :color="university.color" indeterminate width="2"/>
      </div>

      <FloatMessenger :chat_user_id="chat_user_id" height="500px"
                      v-if="this.$store.state.user.currentUser.id > 0 && $vuetify.breakpoint.mdAndUp"/>
    </v-app>
  </div>
</template>

<script>
import Navigation from "@/components/v2/Navigation";
import {getMessaging, getToken} from "@firebase/messaging";
import gadgets from "@/api/gadgets";
import FloatMessenger from "@/components/Messages/FloatMessenger";

export default {
  name: "App",
  components: {Navigation, FloatMessenger},
  data() {
    return {
      loaded: false,
      complete: true,
      chat_user_id: null,
      title: ''
    }
  },
  watch: {
    title() {

    }
  },
  methods: {
    chat(user_id) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.chat_user_id = user_id;
      } else {
        this.$router.push({path: '/messages', query: {chat_user_id: user_id}})
      }
      setInterval(() => {
        this.chat_user_id = null;
      }, 1)
    },
  },
  computed: {
    university() {
      return this.$store.state.university.university;
    }
  },
  async mounted() {
    let token = localStorage.getItem('access_token');
    await this.$store.dispatch('getUniversity', {hostname: location.host});
    if (token) {
      document.title = this.university.name;
      window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      this.$store.dispatch('getUser').then(() => {
        this.$store.dispatch('getUnreadCount');
        this.$vuetify.theme.themes.light.primary = this.university.color;
        this.loaded = true;
        if (this.$store.state.user.currentUser.uni_id !== this.university.id) {
          window.location = 'https://' + this.$store.state.user.currentUser.university.hostname + '/#/authByToken/' + token;
          localStorage.removeItem('access_token');
        }
        const messaging = getMessaging(window.firebase);
        getToken(messaging).then((token) => {
          gadgets.addGadget(token);
        })
      });
      await this.$store.dispatch('getDepartments');
      await this.$store.dispatch('getStudentGroups');
      this.$store.dispatch('getInstitutes');
    } else {
      this.loaded = true;
    }
  }
}
</script>

<style scoped>
.app-content {
  font-family: 'Roboto', sans-serif;
  background: #ebebef !important;
}
</style>
