<template>
  <div>
    <div v-if="!loading">
      <v-text-field v-if="!material" v-model="title" placeholder="Название материала"/>
        <div class="material-page-content">
          <div class="pa-2">
            <h2 v-if="material == null">Создание материала</h2>
            <h2 v-else>Редактирование: {{ material.title }}</h2>
          </div>
          <div style="width: 100%" id="editorjs"></div>
        </div>
        <v-btn fixed right top rounded style="z-index: 100" :loading="loadingSave" color="primary" dark @click="save">
          Сохранить
        </v-btn>
    </div>
  </div>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import EditorJS from '@editorjs/editorjs';
import materials from "@/api/materials.js";
import editor from "@/util/editor.js";
import analytics from "@/api/analytics.js";
export default {
  name: "MaterialEditor",
  data() {
    return {
      editor: null,
      title: "",
      loadingSave: false,
      material: null,
      loading: true
    }
  },
  methods: {
    save() {
      this.loadingSave = true;
      if (this.material == null) {
        this.editor.save().then((outputData) => {
          materials.store({page_content: JSON.stringify(outputData), title: this.title}).then((r) => {
            this.loadingSave = false;
            this.$router.push({name: 'materials.page', params: {id: r.data.id}});
          })
        });
      } else {
        this.editor.save().then((outputData) => {
          materials.update({
            id: this.material.id,
            page_content: JSON.stringify(outputData),
            title: this.title
          }).then((r) => {
            this.loadingSave = false;
            this.$router.push({name: 'materials.page', params: {id: r.data.id}});
          })
        });
      }
    }
  },
  mounted() {
    editor.readOnly = false;
    if (this.$route.params.id > 0) {
      let r = this.$store.getters.isAuth ? materials.auth_item : materials.item;
      r({id: this.$route.params.id}).then((r) => {
        this.material = r.data;
        this.loading = false;
        editor.data = JSON.parse(r.data.content);
        this.title = r.data.title;
        if (!r.data.can_edit) {
          editor.readOnly = true;
          this.$router.push({name: 'materials.page', params: {id: r.data.id}});
        }
        this.editor = new EditorJS(editor);
        analytics.openEditMaterialPage(this.$route.params.id, this.$store.state.user.currentUser.id)
      });
    } else {
      this.loading = false;
      this.editor = new EditorJS(editor);
    }
    // eslint-disable-next-line no-unused-vars

  }
}
</script>

<style>
.ce-block__content,
.ce-toolbar__content {
  max-width: 95% !important;
}


.material-page-content {
  max-width: 986px;
  background: white;
  padding: 20px;
  border-radius: 20px;
  margin: 20px auto 0;
}

.image-tool__caption {
  display: none;
}
</style>
