<script>
import cases from "@/api/cases";

export default {
  name: "CaseCreateComponent",
  mounted() {
    if(!this.$store.state.user.currentUser.id ) return this.$router.push('/auth')
  },
  data() {
    return {
      errors: [],
      loading: false,
      title: '',
      companyName: '',
      companyRepresentativeName: '',
      companyRepresentativePosition: '',
      companyDescription: '',
      problem: '',
      currentState: '',
      product: '',
      additionalInformation: '',
    }
  },
  methods: {
    async save() {
      const data = {
        title: this.title,
        companyName: this.companyName,
        companyRepresentativeName: this.companyRepresentativeName,
        companyRepresentativePosition: this.companyRepresentativePosition,
        companyDescription: this.companyDescription,
        problem: this.problem,
        currentState: this.currentState,
        product: this.product,
        additionalInformation: this.additionalInformation,
      };
      this.loading = true;
      try {
        await cases.create(data)
      } catch (e) {
        this.errors = e.response.data.errors;
      } finally {
        this.loading = false;
      }


    }
  }
}
</script>

<template>
  <v-container style="max-width: 980px">
    <div style="background: white; padding: 20px; border-radius: 20px;">
      <h1 class="mb-2">Добавление кейса</h1>
      <v-form>
        <v-text-field :error-messages="errors.title" placeholder="Безбарьерная среда для маломобильных групп населения"
                      label="Название кейса" rounded
                      style="border-radius: 10px; border-color: #efefef;" v-model="title"
                      outlined/>
        <v-text-field :error-messages="errors.companyName" label="Полное название компании, предоставившей кейс"
                      placeholder="ООО «Югорский Экспертный Центр»" rounded
                      style="border-radius: 10px; border-color: #efefef;" v-model="companyName"
                      outlined/>

        <v-text-field :error-messages="errors.companyRepresentativeName" label="ФИО эксперта"
                      placeholder="Юлия Евгеньевна Ерохина" rounded
                      style="border-radius: 10px; border-color: #efefef;" v-model="companyRepresentativeName"
                      outlined/>

        <v-text-field :error-messages="errors.companyRepresentativePosition" label="Должность эксперта"
                      placeholder="Генеральный директор" rounded
                      style="border-radius: 10px; border-color: #efefef;" v-model="companyRepresentativePosition"
                      outlined/>
        <v-divider/>
        <v-textarea :error-messages="errors.companyDescription" v-model="companyDescription" outlined
                    hint="чем занимается, ассортимент продукции/услуг, оборот, на каких рынках работает, используемые технологии, информация о составе рабочего коллектива"
                    label="Описание деятельности компании "/>
        <v-textarea :error-messages="errors.problem" v-model="problem" outlined label="Описание проблемной ситуации"/>
        <v-textarea :error-messages="errors.currentState" v-model="currentState" outlined label="Как пытались решить?"/>
        <v-textarea :error-messages="errors.product" v-model="product" outlined
                    label="Желаемый образ результата (продукта)"/>
        <v-textarea :error-messages="errors.additionalInformation" v-model="additionalInformation" outlined
                    label="Дополнительные материалы"/>
        <v-btn @click="save" :loading="loading" outlined color="blue">Добавить кейс</v-btn>
      </v-form>
    </div>

  </v-container>
</template>

<style scoped>

</style>
