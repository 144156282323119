<template>
  <div>
    <div>
        <v-spacer/>
        <v-btn fixed top right v-if="$store.state.user.currentUser.id > 0 && material.can_edit" rounded
               @click="$router.push({name: 'materials.creator', params: {id: material.id}})" dark color="primary">
          Редактировать
        </v-btn>
        <div class="material-page-content">
          <div class="pa-4">
            <UserLinkComponent :user="material.user"/>
            <h1 class="mt-4">
              {{ material.title }}
            </h1>
          </div>
          <div style="width: 100%" id="editorjs">

          </div>
        </div>
    </div>

  </div>

</template>

<script>

import EditorJS from '@editorjs/editorjs';
import materials from "@/api/materials.js";
import editor from "@/util/editor.js";
import UserLinkComponent from "@/components/Profile/UserLinkComponent.vue";
import analytics from "@/api/analytics.js";

export default {
  name: "MaterialEditor",
  components: {UserLinkComponent},
  data() {
    return {
      material: null
    }
  },
  methods: {},
  mounted() {

    if (this.$store.getters.isAuth) {
      materials.auth_item({id: this.$route.params.id}).then((r) => {
        this.material = r.data;
        editor.data = JSON.parse(r.data.content);
        editor.readOnly = true;
        this.editor = new EditorJS(editor);
        analytics.openMaterialPage(this.$route.params.id, this.$store.state.user.currentUser.id)
      })
    } else {
      materials.item({id: this.$route.params.id}).then((r) => {
        this.material = r.data;
        editor.data = JSON.parse(r.data.content);
        editor.readOnly = true;
        this.editor = new EditorJS(editor);
        analytics.openMaterialPage(this.$route.params.id, null)
      })
    }

  }
}
</script>

<style>
.ce-block__content,
.ce-toolbar__content {
  max-width: 95% !important;
}


.image-tool__caption {
  display: none;
}

.material-page-content {
  max-width: 986px;
  background: white;
  padding: 20px;
  border-radius: 20px;
  margin: 20px auto 0;
}
</style>
