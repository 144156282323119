<script>
import CaseListItemComponent from "@/components/Cases/CaseListItemComponent.vue";
import cases from "@/api/cases";

export default {
  name: "CaseListComponent",
  components: {CaseListItemComponent},
  data() {
    return {
      cases: []
    }
  },
  async mounted() {
    this.cases = (await cases.all()).data;
  }
}
</script>

<template>
  <v-container>
    <v-row>
      <v-col sm="12" lg="12">
        <div class="d-flex align-center" style="gap: 10px">
          <h1>Кейсы</h1>
          <v-btn
              color="blue"
              light
              outlined
              style="border-radius: 10px"
              elevation="0"
              to="/cases/create"
          >
            Добавить кейс
          </v-btn>
        </div>

      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="projectCase in cases" :key="projectCase.id" sm="12" cols="12" md="4" lg="4">
        <CaseListItemComponent :projectCase="projectCase"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
