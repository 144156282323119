<script>
import CardUI from "@/components/UI/CardUI.vue";
import UserLinkComponent from "@/components/Profile/UserLinkComponent.vue";

export default {
  name: "CaseListItemComponent",
  components: {UserLinkComponent, CardUI},
  props: {
    projectCase: {}
  }
}
</script>

<template>
  <CardUI  @click.native="$router.push('/cases/'+projectCase.id)">
    <template  v-slot:footer>
      <UserLinkComponent :text="$moment.utc(projectCase.created_at).local().fromNow()" :user="projectCase.user"/>
      <v-spacer/>
    </template>
    <template v-slot:body>
      <h4>{{projectCase.title}}</h4>
      <div style="font-size: 12px; font-weight: 400">
        {{projectCase.companyName}}
      </div>
      <p class="mt-2">
      {{projectCase.problem.slice(0, 100)}}...</p>
    </template>
  </CardUI>
</template>

<style scoped>

</style>
